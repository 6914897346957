import { USER_TOKEN } from '@/constants/global';
import { IEffectsCommandMap } from '@/interface/store';
import { UserInfo } from '@/interface/user';
import { getUserInfo, patchInfo, patchUserInfo, logout } from '@/services/user';
import { humpToUnderline } from '@/utils/format';
import { RootState } from '.';

export type UserState = {
  userInfo: UserInfo;
  token: string;
  bindWechatVisible: boolean;
};

export default {
  namespace: 'user',
  state: {
    token: '',
    userInfo: null,
    bindWechatVisible: false,
  },
  reducers: {
    setState(state: any, { type, ...ret }: any) {
      return {
        ...state,
        ...ret,
      };
    },
    setToken(state: UserState, action) {
      localStorage.setItem(USER_TOKEN, action.payload);
      return { ...state, token: action.payload };
    },
    setUserInfo(state: UserState, action) {
      return { ...state, userInfo: action.payload };
    },
  },
  effects: {
    *getUserInfo(_, { call, put, select }: IEffectsCommandMap) {
      const { token } = yield select((state: RootState) => state.user);
      if (!token) {
        return;
      }
      const { data } = yield call(getUserInfo);
      const userInfo: any = {};
      data &&
        Object.entries(data).forEach(([key, value]) => {
          userInfo[humpToUnderline(key)] = value;
        });
      userInfo.token = token;
      yield put({
        type: 'setUserInfo',
        payload: userInfo,
      });
    },
    // node更新用户信息
    *patchInfo({ payload }, { call, put }: IEffectsCommandMap) {
      const { data } = yield call(patchInfo, payload);
      yield put({
        type: 'setToken',
        payload: data.token,
      });
      yield put({
        type: 'setUserInfo',
        payload: data,
      });
    },
    // java留资更新信息
    *patchUserInfo({ payload }, { call, put, select }: IEffectsCommandMap) {
      console.log('payload', payload);
      const { company, job, accountName, contactName, shopName } = payload;
      let canGetCoupon = false;
      const currentUser = yield select((state: RootState) => state.user.userInfo);
      console.log('currentUser', currentUser);
      const { data } = yield call(patchUserInfo, payload);
      const userInfo: any = {};
      data &&
        Object.entries(data).forEach(([key, value]) => {
          userInfo[humpToUnderline(key)] = value;
        });
      console.log('userInfo', userInfo);
      if (company && job && currentUser.profile_completed_state !== userInfo.profile_completed_state) {
        canGetCoupon = true;
      }
      if (
        (accountName || contactName || shopName) &&
        currentUser.shop_info_completed_state !== userInfo.shop_info_completed_state
      ) {
        canGetCoupon = true;
      }
      yield put({
        type: 'setUserInfo',
        payload: userInfo,
      });
      return { canGetCoupon };
    },
    *logout(_, { put, call }: IEffectsCommandMap) {
      yield call(logout);
      localStorage.removeItem(USER_TOKEN);
      yield put({ type: 'setToken', payload: '' });
      yield put({ type: 'setUserInfo', payload: null });
    },
    *fetchBindWechatVisible({ payload }, { put }: IEffectsCommandMap) {
      yield put({ type: 'setState', bindWechatVisible: payload });
    },
  },
  subscriptions: {
    setup({ dispatch }) {
      if (typeof window !== 'undefined') {
        localStorage.getItem(USER_TOKEN) === 'null' && localStorage.removeItem(USER_TOKEN);
        localStorage.getItem(USER_TOKEN) &&
          localStorage.getItem(USER_TOKEN) !== 'null' &&
          dispatch({ type: 'setToken', payload: localStorage.getItem(USER_TOKEN) });
        dispatch({ type: 'getUserInfo' });
      }
    },
  },
};
