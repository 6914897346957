import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import type { AxiosRequestConfig, AxiosInstance } from 'axios';
import { initStore } from '@/store';
import { API_HOST, API_NEXT_HOST, IS_DEV } from '@/constants/env';
import { USER_TOKEN } from '@/constants/global';
import { logout } from '@/services/user';
import { isServer } from './is';

const getAxiosInstance = (host?: string, basePath?: string, config?: AxiosRequestConfig, handle?) => {
  const baseURL = (host || '') + (basePath || '');
  const instance = axios.create({
    ...(baseURL && { baseURL }),
    // headers: { 'X-Custom-Header': 'cbndata', Authorization: token },
    ...config,
    // withCredentials: true,
  });
  if (handle) {
    handle(instance);
  } else {
    instance.interceptors.request.use(
      config => {
        const token = isServer() ? '' : localStorage.getItem(USER_TOKEN) || '';
        token && (config.headers.common['Authorization'] = token);
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    instance.interceptors.response.use(
      response => {
        return response.data;
      },
      async (error: AxiosError<ApiErrorResponseData>) => {
        toast.error(error.response?.data?.error || error.message);
        return Promise.reject(error);
      }
    );
  }
  return instance;
};
export type ApiErrorResponseData = {
  error: string;
};
// 老消费站Ruby接口
const apiRequest = getAxiosInstance(API_HOST, '/api', null, (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    config => {
      const token = isServer() ? '' : localStorage.getItem(USER_TOKEN) || '';
      token && (config.headers.common['Authorization'] = token);
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    response => {
      return response.data;
    },
    async (error: AxiosError<ApiErrorResponseData>) => {
      if (error.response.status === 401) {
        if (error.response.config.url !== '/v2/sessions') {
          // goToLogin();
          await logout();
          localStorage.removeItem(USER_TOKEN);
          location.reload();
        }
      }
      toast.error(error.response?.data?.error || error.message);
      return Promise.reject(error);
    }
  );
});
// 新消费站Java接口
const nextApiRequest = getAxiosInstance(API_NEXT_HOST, '/consumerstation/web', null, (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    config => {
      const token = isServer() ? '' : localStorage.getItem(USER_TOKEN) || '';
      token && (config.headers.common['Authorization'] = token);
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    async response => {
      const { dispatch } = initStore();
      // headers里的noAuthHandle属性是为了让某些接口可以不带token调用，可是后端会返回401，为了静默处理不报错，且不弹窗重新登录，所以添加此配置字段
      if (response.data.code !== 200 && !response.config.headers.noAuthHandle) {
        toast.error(response?.data.msg);
      }
      if (response.data.code === 401 && !response.config.headers.noAuthHandle) {
        await logout();
        dispatch({ type: 'user/setToken', payload: '' });
        dispatch({ type: 'modal/openUserLoginModal', payload: 'login' });
      }
      return response.data;
    },
    (error: AxiosError<ApiErrorResponseData>) => {
      // if (error.response.status === 401) {
      //   useStore.setState({ token: '' });
      // }
      toast.error(error.response?.data?.error || error.message);
      return Promise.reject(error);
    }
  );
});
// 老消费站Node.js(Egg)接口服务
const nodeApiRequest = getAxiosInstance(IS_DEV ? 'https://staging.cbndata.com' : null);

export default apiRequest;

export { apiRequest, nextApiRequest, nodeApiRequest, getAxiosInstance };
