export type CbndataboxState = {
  isClickConsult: boolean;
  // hasConsultedPrice: boolean;
};

export default {
  namespace: 'cbndatabox',
  state: {
    isClickConsult: false,
    // hasConsultedPrice: false, // 用户是否已咨询过某个产品的定价
  },
  reducers: {
    setIsClickConsult(state: CbndataboxState, action) {
      return { ...state, isClickConsult: action.payload };
    },
    // setHasConsultedPrice(state: CbndataboxState, payload) {
    //   const { hasConsultedPrice } = payload;
    //   return { ...state, hasConsultedPrice };
    // },
  },
  effects: {
    // *queryHasConsultedPrice({ payload }, { call, put }) {
    //   const { boxProductId } = payload;
    //   const { data } = yield call(queryHasUserConsulted, { boxProductId });
    //   yield put({
    //     type: 'setHasConsultedPrice',
    //     payload: data.hasSubmit,
    //   });
    // },
  },
};
