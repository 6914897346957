import { ReactElement } from 'react';

export type ModalState = {
  isOpen: boolean;
  content: ReactElement | null;
  userLoginVisible: boolean;
  loginType: 'login' | 'resetPassword' | 'register';
  closeCallback: () => any;
};

export default {
  namespace: 'modal',
  state: {
    isOpen: false,
    content: null,
    // 用户登录Modal框相关的state
    userLoginVisible: false,
    loginType: 'login',
    closeCallback: null,
  },
  reducers: {
    open(state: ModalState, action) {
      console.log(state);
      const { content, closeCallback } = action.payload;
      return {
        content,
        isOpen: true,
        closeCallback,
      };
    },
    close(state) {
      state.closeCallback && state.closeCallback();
      return {
        content: '',
        isOpen: false,
      };
    },
    openUserLoginModal(state: ModalState, action: { payload: ModalState['loginType'] }) {
      return { ...state, userLoginVisible: true, loginType: action.payload };
    },
    closeUserLoginModal(state: ModalState) {
      return { ...state, userLoginVisible: false, loginType: '' };
    },
  },
  effects: {},
};
