import { get, isPlainObject, every } from 'lodash';

export function isServer() {
  return (
    Object.prototype.toString.call(global.process) === '[object process]' ||
    !process.browser ||
    typeof window === 'undefined'
  );
}

export function isFunction(o) {
  return typeof o === 'function';
}

export function isHTMLElement(node) {
  return typeof node === 'object' && node !== null && node.nodeType && node.nodeName;
}

export function isString(str) {
  return typeof str === 'string';
}
export function isNumber(n) {
  return typeof n === 'number';
}
type IBaseType = string | number | null | undefined;
export function isBaseType(value) {
  return value === null || typeof value !== 'object';
}

export function isBaseEqual(value: IBaseType, other: IBaseType) {
  if (!isBaseType(value) || !isBaseType(other)) {
    /* eslint no-console: 0 */
    console.warn('isBaseEqual argumnets must be [string | number | null | undefined]', value, other);
    return false;
  }
  return String(value) === String(other);
}

export type IProperties = Array<Parameters<typeof get>[1]>;

// 检测是否 对象上存在 某些属性
export function isHasProperty<T = any>(obj: T, keys: IProperties) {
  if (!obj) return false;
  return !keys.find(path => isNil(get(obj, path)));
}

function isNil(v) {
  return v === undefined || v === null || v === '';
}

export function isLooseEmpty<T extends any>(obj: T) {
  if (!obj) {
    return true;
  } else if (isPlainObject(obj)) {
    const res = every(obj as object, isNil);
    if (res) {
      return true;
    }
  }
  return false;
}
