import queryString, { ParsedQuery } from 'query-string';
import { reduce } from 'lodash';
import { FILTER_KEYS, ORIGINAL_KEYS } from '@/constants/swr';

export function replaceHref(nextQuery: ParsedQuery, isGiveQuery = false) {
  const urlObj = queryString.parseUrl(location.href);
  const mergeQuery = { ...urlObj.query, ...nextQuery };
  return `${urlObj.url}?${queryString.stringify(isGiveQuery ? {} : mergeQuery)}`;
}

type IParamsVal = string | string[];
export function validateParams(val: IParamsVal, filterKeys = FILTER_KEYS) {
  if (Array.isArray(val)) {
    const newVal = val.filter(v => filterKeys.findIndex(k => k == v) < 0);
    return newVal.length > 0 ? newVal : undefined;
  }
  const index = filterKeys.findIndex(k => k === val);
  return index < 0 ? val : undefined;
}

export function delReqPayload(_payload: { [key: string]: IParamsVal }, filterKeys?: string[]) {
  const payload = { ..._payload } || {};
  Object.keys(payload).forEach(key => {
    const val = payload[key];
    const newVal = validateParams(val, filterKeys);
    payload[key] = newVal;
    if (newVal === undefined) {
      delete payload[key];
    }
  });
  return payload;
}

export const defaultSerializeReq = serializeParams();

export function serializeParams(originalKeys: string[] = ORIGINAL_KEYS, filterKeys?: string[]) {
  return params => {
    return reduce(
      params,
      (s, val, key) => {
        const newVal = validateParams(val, filterKeys);
        if (newVal === undefined) {
          return s;
        }
        const isOriginal = originalKeys?.find(originKey => originKey === key);
        if (isOriginal) {
          s[key] = newVal;
        } else {
          s[`search[${key}]`] = newVal;
        }
        return s;
      },
      {}
    );
  };
}
export function queryParse(params = {}) {
  const search = typeof params === 'string' ? params : window.location.search;
  const query = queryString.parse(search, { arrayFormat: 'bracket' });
  if (typeof params === 'string') return query;
  return {
    ...query,
    ...params,
  };
}
export function querySearch(query) {
  return queryString.stringify(query, { arrayFormat: 'bracket' });
}
