export const USER_TOKEN = 'authorization';

export const REDIRECT_URL = 'redirect_url';

export const ROOT_ID = '__next';

export const USER_INFO = 'user_info';

export const LOGIN_ROUTES = '/login';

export const NO_AUTH_ROUTES = ['/', LOGIN_ROUTES, '/register'];

export const DEFAULT_INPUT_MAX_LENGTH = 30;

export const DEFAULT_INPUT_MOBILE_LENGTH = 11;

export const QR_SRC = '';

export const TEL_MOBILE = '18888888888';

export const INDUSTRY_OPTIONS = [
  { title: '广告/公关/媒介机构', value: '广告/公关/媒介机构' },
  { title: '品牌商家', value: '品牌商家' },
  { title: '市场营销机构', value: '市场营销机构' },
  { title: '电商平台商家', value: '电商平台商家' },
  { title: '互联网公司', value: '互联网公司' },
  { title: '政府以及公共服务业', value: '政府以及公共服务业' },
  { title: '银行/金融', value: '银行/金融' },
  { title: '媒体/自媒体/KOL', value: '媒体/自媒体/KOL' },
  { title: '咨询研究/数据机构', value: '咨询研究/数据机构' },
  { title: '影视/娱乐公司', value: '影视/娱乐公司' },
  { title: '明星经济公司', value: '明星经济公司' },
  { title: '房地产商', value: '房地产商' },
  { title: '投资机构', value: '投资机构' },
  { title: '百货公司', value: '百货公司' },
  { title: '公益组织', value: '公益组织' },
  { title: '其他', value: '其他' },
];

export const USER_INFO_FORM_LABEL_NAME_MAP = {
  name: '姓名',
  company: '公司',
  job: '职位',
};

export const PORDUCT_TAG_MAP = {
  1: {
    text: 'Hot',
    bgColor: '#f75c2f',
  },
  2: {
    text: 'New',
    bgColor: '#f51e2d',
  },
};

export const COMPANY_SIZE_MAP = {
  '-1': '',
  0: '0-20人',
  20: '20-99人',
  100: '100-499人',
  500: '500-999人',
  1000: '1000-9999人',
  10000: '10000人以上',
};
