export const APP_ENV = process.env.APP_ENV;
export const IS_DEV = APP_ENV === 'development';
export const IS_DAILY = APP_ENV === 'daily';
export const IS_PROD = APP_ENV === 'production';

export const SITE_TITLE = 'CBNData-第一财经商业数据中心';
export const SITE_DESCRIPTION =
  '第一财经商业数据中心（CBNData）隶属于上海文化广播影视集团（SMG），是是国内领先的消费研究机构及数字化增长服务商。业务场景已覆盖传统零售与电商、互联网消费、内容社交、医疗教育、金融理财等多领域，面向品牌企业全面输出数据应用、内容运营、整合营销、销售转化等数字化增长服务。';
export const SITE_IMAGE = 'https://assets-oss.cbndata.com/cbndata-refactor-fe/Fn2iRP4ETkuL1tvuUwLxzeBNZ2ii.ico';
export const SITE_KEYWORD =
  'cbn，CBNData，数据报告，第一财经，消费行业研究报告，营销案例，整合营销，数字营销，新零售，消费新趋势，数智化经济，数字化经济';

export const _GA_ID = 'UA-66946541-12';
export const _BAIDU_ID = '8d5cafbbcfea1e321d4fe41021e86d36';

export const API_HOST = IS_PROD ? 'https://www.cbndata.com' : 'https://staging.cbndata.com';
export const API_NEXT_HOST = IS_PROD ? 'https://api-next.cbndata.com' : 'https://api-next-staging.cbndata.com';
