import {
  LoginRequestParams,
  PatchUserInfoRequestParams,
  ResetPasswordRequestParams,
  SignUpRequestParams,
  UserInfo,
} from '@/interface/user';
import axios, { nextApiRequest, nodeApiRequest } from '@/utils/request';

// 旧版Ruby获取用户信息（可以不用了）
// export async function getUserInfo() {
//   return axios.request<{ data: UserInfo }, { data: UserInfo }>({
//     url: '/v2/user',
//     method: 'get',
//   });
// }

// 新版java获取用户信息
export async function getUserInfo() {
  return nextApiRequest.request<{ data: UserInfo }, { data: UserInfo }>({
    url: '/v1/user',
    method: 'get',
  });
}

// 旧版Node更新用户信息
export async function patchInfo(data: { email: string }) {
  return nodeApiRequest.request<UserInfo>({
    url: '/patchInfo',
    method: 'patch',
    data: {
      user: data,
    },
  });
}

// java更新用户信息
export async function patchUserInfo(data: PatchUserInfoRequestParams) {
  return nextApiRequest.request<UserInfo>({
    url: '/v1/user',
    method: 'patch',
    data,
  });
}

export async function loginByNode(data: LoginRequestParams) {
  return nodeApiRequest.request<{ data: UserInfo }, { data: UserInfo }>({
    url: `/login`,
    method: 'post',
    data,
  });
}

export async function login(data: LoginRequestParams) {
  return axios.request<{ data: UserInfo }, { data: UserInfo }>({
    method: 'post',
    url: '/v2/sessions',
    data,
  });
}

export async function signup(data: SignUpRequestParams) {
  return axios.request<UserInfo>({
    method: 'post',
    url: '/v2/user',
    data,
  });
}
// 微信登录获取的token不能下载报告，需要调用该接口换取新的token同步登录态
export async function syncLoginStateByToken(params) {
  return nodeApiRequest.request({
    url: '/siginByToken',
    method: 'get',
    data: params,
  });
}
export async function resetPassword(data: ResetPasswordRequestParams) {
  return axios.request<UserInfo>({
    method: 'post',
    url: '/v2/user/reset_password',
    data,
  });
}

export async function bindwechat(data) {
  return axios.request({
    url: '/v2/user/bind_wechat',
    method: 'post',
    data,
  });
}

export async function logout() {
  return nodeApiRequest.request({
    url: `/logout`,
    method: 'get',
  });
}
