import { useMemo } from 'react';
import { create } from 'dva-core';
import { Store } from 'redux';
import createLoading from 'dva-loading';
import * as models from '@/models';
import { isServer as checkServer } from '@/utils/is';
import useCache from '@/hooks/use-cache';
import { IDvaInstance, IModel, IDvaOption } from '@/interface/store';
import { IS_DEV } from '../constants/env';

const PATCH = '@@DVA_PATCH';
const RESET = '@@DVA_RESET';

// 客户端缓存实例
let _app;
function initApp(opts: IDvaOption = {}) {
  let app = _app ?? create(opts);
  if (_app && opts?.initialState) {
    delete opts.initialState['@@flag'];
    app = create(opts);
    _app = undefined;
  }
  if (!_app) {
    // 暂不支持 动态注册
    app.model({
      namespace: '@@flag',
      state: Date.now(),
    } as IModel);
    Object.values(models).forEach(m => registerModel(app, m as any));
    app.router = () => {};
    app.use({
      onError(err: Error) {
        if (IS_DEV) {
          console.warn('global error', err);
        }
      },
      onReducer: reducer => {
        return (state, action) => {
          switch (action.type) {
            case PATCH:
              return { ...state, ...action.payload };
            case RESET:
              return action.payload;
            default:
              return reducer(state, action);
          }
          // return reducer(state, action);
        };
      },
      ...createLoading({
        effects: true,
      }),
    });
    app.start();
  }
  // For SSG and SSR always create a new store
  if (!checkServer()) {
    _app = app;
  }
  return [app._store, app] as [Store, IDvaInstance];
}

function registerModel(app: IDvaInstance, model: IModel) {
  const _models: IModel[] = app['_models'] || [];
  if (!_models.some(m => m.namespace === model.namespace)) {
    app.model(model);
  } else if (IS_DEV) {
    console.warn(`[app.model] namespace should be unique`, model.namespace);
  }
}

export function initStore(initialState?: IDvaOption['initialState']) {
  const [store] = initApp({ initialState });
  return store;
}

export function useStore(initialState?: IDvaOption['initialState']) {
  const [_initialState] = useCache(initialState);
  const store = useMemo(() => initStore(_initialState), [_initialState]);
  return store;
}
