import type { ConfigInterface } from 'swr';
import { dequal } from 'dequal';

import { isServer } from '@/utils/is';

// swr 默认配置
export const SWR_DEFAULT_CONFIG = {
  shouldRetryOnError: !isServer(),
  // 禁止聚焦 请求
  revalidateOnFocus: false,
  // 太多 频繁更新的东西（且是第三方修改的）
  dedupingInterval: 1000 * 2,
  focusThrottleInterval: 1000 * 60 * 5,
  errorRetryCount: 1,
  // loadingTimeout: 1000 * 20,
  compare: dequal,
} as ConfigInterface;

// 默认过滤的 请求无效 参数值
export const FILTER_KEYS = [undefined, 'all', '', null, 'undefined', 'null'];
// 原始参数 key
export const ORIGINAL_KEYS = ['page', 'per', 'order'];

export const NOTICE_REFRESH_TIME = 10000; // 10s
