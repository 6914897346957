import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { message } from 'antd';
import { queryParse, querySearch } from '@/utils/url';
import axios from '@/utils/request';
import { initStore } from '@/store';
import { syncLoginStateByToken } from '@/services/user';
import { USER_TOKEN } from '@/constants/global';
/**
 * @param date Date
 * @param fmt  'YYYY-MM-DD HH:mm:ss'
 */
export function dateFtt(date: Date | string | number, fmt = 'YYYY-MM-DD HH:mm:ss') {
  let isValidate = dayjs(date).isValid();
  if (!isValidate) {
    try {
      isValidate = String(new Date(date)) !== 'Invalid Date';
    } catch (error) {
      console.warn('dateFtt', error);
    }
  }
  let res = date;
  try {
    if (isValidate && date) {
      res = dayjs(date).format(fmt);
    }
  } catch (error) {}

  return (res as string) || '';
}

export async function delay(timeout = 500) {
  return new Promise(r => {
    setTimeout(() => {
      r(timeout);
    }, timeout);
  });
}

//
export function encryptPhone(phone: number | string) {
  if (Number(phone) && String(phone).length === 11) {
    const mobile = String(phone);
    const reg = /^(\d{3})\d{4}(\d{4})$/;
    return mobile.replace(reg, '$1****$2');
  }
  return phone;
}

// 文字换行
export function strSpace(str: string, len = 4, separator = '\n') {
  return ((str || '').match(new RegExp(`.{1,${len}}`, 'g')) || []).join(separator);
}

// 分割文字
export function strSplit(str: string, separator = /[,，、]/) {
  return (str || '').split(separator);
}

// 截取第一个的文字
export function truncated(str: string, num = 1) {
  if (!str) return '';
  let s = '';
  for (const v of str) {
    s += v;
    num--;
    if (num <= 0) {
      break;
    }
  }
  return s;
}

/**
 * 限制只能输入整数
 * @param value
 */
export function limitDecimals(value: string | number): string {
  return `${value}`.replace(/^(0+)|[^\d]+/g, '');
}

/**
 * 通过Field字段名转换成resource中的key名
 * @param key string form field
 * @returns Array string[]，返回对象的路径
 * @description form字段 like_tasks.name  task_id
 * 1. 先处理掉like_
 * 2. 用split进行截取（'.'）,除最后一个数组外进行单词的单复数处理
 */
export const getResourceKeyFromFormField = (key: string): string[] => {
  if (!key) return;
  const afterFilterLike = key.replace(/^like_/, '');
  const splitArr = afterFilterLike.split('.');
  return splitArr.map((w, i) => (i === splitArr.length - 1 ? w : pluralize.singular(w)));
};

export function detectIE() {
  const ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

/**
 * @description 生成随机字符串
 * @param  { number } length - 字符串长度
 * @returns { string }
 */
export function randomString(length = 10, prefix = '') {
  let str = Math.random().toString(36).substr(2);
  if (str.length >= length) {
    return prefix + str.substr(0, length);
  }
  str += randomString(length - str.length);
  return prefix + str;
}

export function uniqBy<T = any>(arr: T[], fn: string | ((d: T) => any), obj = {}) {
  arr.reverse().forEach(item => (obj[typeof fn === 'function' ? fn(item) : get(item, fn)] = item));
  return Object.values(obj);
}
type IPath = string | number | Array<string | number>;
export function get<T = any>(source: T, path: IPath, defaultValue = undefined) {
  // a[3].b -> a.3.b -> ['a', 3, 'b']
  const paths = Array.isArray(path)
    ? path
    : String(path)
        .replace(/\[(\d+)\]/g, '.$1')
        .split('.');
  let result = source;
  for (const p of paths) {
    result = Object(result)[p];
    if (result === undefined) {
      return defaultValue;
    }
  }
  return result;
}

export function checkParent(pid: string, child) {
  let node = child.parentNode;
  const parent = document.getElementById(pid);

  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

export const WX_ID = 'wxc10409226d71fb93';
// 微信开放平台 appid
export const OPEN_WX_ID = 'wxb620545d05df5a8b';
export function checkWechat() {
  const ua = window.navigator.userAgent.toLowerCase();
  const match = ua.match(/MicroMessenger/i) || [];
  if (match[0] === 'micromessenger') {
    return true;
  }
  return false;
}
export function loginInwechat() {
  const isMobile = /^m\./.test(location.host);
  const redirect_uri = location.href;
  // const host = process.env.NODE_ENV === 'production' ? 'm.cbndata.com' : 'm.staging.cbndata.com';
  const host = 'm.cbndata.com';
  const link = checkWechat()
    ? `https://${host}/weixin-code-proxy?appid=${WX_ID}&&connect_redirect=1&redirect_uri=${encodeURIComponent(
        redirect_uri
      )}&scope=snsapi_userinfo&state=wechat_login_app#wechat_redirect`
    : `https://${host}/weixin-code-proxy?appid=${OPEN_WX_ID}&redirect_uri=${encodeURIComponent(
        redirect_uri
      )}&scope=snsapi_login&state=wechat_login_web#wechat_redirect`;
  if (isMobile) {
    window.location.href = link;
  } else {
    window.open(
      link,
      '_blank',
      'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=600, left=100, top=100'
    );
  }
}

export const HistoryListener = async router => {
  const { dispatch } = initStore();
  const query = queryParse();
  const { code, state, ...ret } = query;
  // 同步路由
  // 不是子页面且以及回调函数
  if (!window.opener && code && /^wechat_login/.test(state as string)) {
    querySearch(ret) ? router.replace(`${router.pathname}?${querySearch(ret)}`) : router.replace(`${router.pathname}`);
    try {
      const res = await axios.request({
        url: '/v2/wechat_union_users',
        method: 'post',
        data: {
          code,
          app_type: state.lastIndexOf('app') > -1 ? 'cbndata' : 'cbndata_web',
        },
      }); // 移除code
      sessionStorage.setItem('_cbndata_wechat_user', JSON.stringify(res?.data?.wechat_user));
      if (!res.data.user) {
        dispatch({ type: 'user/fetchBindWechatVisible', payload: true });
        return;
      }
      syncLoginStateByToken({ token: res?.data?.user?.token });
      localStorage.setItem(USER_TOKEN, res?.data?.user?.token);
      dispatch({ type: 'user/setToken', payload: res?.data?.user?.token });
      dispatch({ type: 'user/setUserInfo', payload: res?.data?.user });
    } catch (error) {
      console.log('error :>> ', error);
      message.error(error.message);
    }
  }
};

export const splitArray = <T extends any[]>(arr: T extends (infer R)[] ? R[] : T, length: number): T[] => {
  if (!Array.isArray(arr)) {
    return arr;
  }
  return arr.reduce((acc, cur: T, currentIndex) => {
    const index = parseInt('' + currentIndex / length);
    (acc[index] || (acc[index] = [])).push(cur);
    return acc;
  }, []);
};

export const RedirectProject = () => {
  return `var parser = new UAParser();
  var stagingHost = ['staging.cbndata.com']
  var masterHost = ['www.cbndata.com','cbndata.com']
  if (parser.getDevice().type) {
    var _redirectUrI = '';
    if (stagingHost.indexOf(window.location.host)>-1) {
      _redirectUrI = 'https://m.staging.cbndata.com' + location.pathname + location.search + location.hash;
    } else if(masterHost.indexOf(window.location.host)>-1) {
      _redirectUrI = 'https://m.cbndata.com' + location.pathname + location.search + location.hash;
    }
    _redirectUrI && window.location.replace(_redirectUrI);
  }`;
};
